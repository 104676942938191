.github-card {
    color: #24292e;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    display: flex;
  }
  
  .profile-container {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding-right: 2rem;
    width: 30%;
  }
  
  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .profile-details {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding-left: 1rem;
    margin-left: 2rem;
  }
  
  .full-name {
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-size: 24px;
    font-weight: 600;
    color: #24292e;
  }
  
  .username {
    margin: 0;
    font-size: 16px;
    color: #586069;
  }
  
  .info-items {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
  }
  
  .info-item {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #586069;
    font-size: 14px;
  }
  
  .repositories-section,
  .contributions-section {
    width: 80%;
  }
  
  h3 {
    margin: 0 0 16px 0;
    font-size: 16px;
    font-weight: 600;
  }
  
  .repo-list,
  .contributions-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .repo-item {
    padding: 12px;
    background-color: #f6f8fa;
    border-radius: 6px;
    transition: background-color 0.2s;
  }
  
  .repo-item:hover {
    background-color: #f1f2f4;
  }
  
  .repo-header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
  }
  
  .repo-name {
    color: #0366d6;
    text-decoration: none;
    font-weight: 500;
  }
  
  .repo-name:hover {
    text-decoration: underline;
  }
  
  .repo-meta {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 12px;
    color: #586069;
  }
  
  .language-dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
  }
  
  .contribution-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    background-color: #f6f8fa;
    border-radius: 6px;
  }
  
  .contribution-count {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-left: auto;
    color: #586069;
    font-size: 14px;
  }
  
  @media (max-width: 768px) {
    .profile-container {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .info-items {
      align-items: center;
    }
  }





  .loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: auto;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  