.leetcode-card {
    display: flex;
    gap: 24px;
    background: #ffffff;
    border-radius: 8px;
    padding: 10px;
  }
  
  .card-left {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 16px;
    color: #586069;
  }
  
  .card-right {
    flex: 2;
    display: flex;
    flex-direction: column;
  }
  
  .profile-circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #f0f0f0;
    margin-bottom: 16px;
  }
  
.leet-profile-info {
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }
  
  .info-item {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #666;
    font-size: 14px;
  } 

  .label {
    color: #666;
  }
  
  .value {
    color: #676666;
    font-weight: 500;
  }
  
  .problem-stats h3,
  .submission-graph h3 {
    margin: 0 0 16px 0;
    font-size: 16px;
    color: #333;
  }
  
  .difficulty-boxes {
    display: flex;
    gap: 12px;
    margin-bottom: 24px;
  }
  
  .diff-box {
    flex: 1;
    padding: 8px 16px;
    border-radius: 4px;
    text-align: center;
  }
  
  .diff-box .count {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 4px;
  }
  
  .diff-box .label {
    font-size: 12px;
  }
  
  .diff-box.easy {
    background: #e7f9f6;
    color: #00b8a3;
  }
  
  .diff-box.medium {
    background: #fff7e6;
    color: #ffa116;
  }
  
  .diff-box.hard {
    background: #ffe6e6;
    color: #ff375f;
  }
  
  .badges-section h3 {
    margin: 0 0 12px 0;
    font-size: 16px;
    color: #333;
  }
  
  .badges-container {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }
  
  .badge-item img {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
  
  .submission-graph {
    background: #f6f8fa;
    padding: 16px;
    border-radius: 8px;
    margin-top: auto;
  }
  
  @media (max-width: 768px) {
    .leetcode-card {
      flex-direction: column;
    }
    
    .difficulty-boxes {
      flex-direction: column;
    }
  }



  /* Add these styles to your existing CSS */

.custom-tooltip {
  background: white;
  border: 1px solid #e1e4e8;
  border-radius: 6px;
  padding: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  min-width: 200px;
}

.tooltip-title {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  color: #333;
  margin-bottom: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
}

.tooltip-meta {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 12px;
}

.tooltip-meta .language {
  color: #666;
}

.tooltip-meta .status {
  display: inline-block;
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: 500;
}

.tooltip-meta .status.accepted {
  background: #e7f9f6;
  color: #00b8a3;
}

.tooltip-meta .status.wrong.answer {
  background: #ffe6e6;
  color: #ff375f;
}

.tooltip-meta .status.runtime.error {
  background: #fff7e6;
  color: #ffa116;
}

.tooltip-meta .time {
  color: #666;
}

.submission-graph {
  position: relative;
}



.graph2{
  margin-left: -2rem;
}