  body {
    min-height: 100vh;
  }

  .straight{
    display: flex;
    flex-direction: column;
    gap: .6rem;
    margin-bottom: .3rem;
    font-weight: bold;
  }
  .dashboard-container {
    display: flex;
    background-color: #e9effe;
  }



  .dashboard-container {
    padding: 2rem;
    display: grid;
    gap: 2rem;
    margin: 0 auto;
    margin-left: 16rem;
  }
  
  .main-content {
    flex: 1;
    transition: margin-left 0.3s;
    padding: 2rem;
  }
  
  
  h1 {
    font-size: 1.875rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .subtitle {
    color: #6b7280;
    margin-bottom: 2rem;
  }
  
  /* Grid Layouts */
  .metrics-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
  
  @media (min-width: 768px) {
    .metrics-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 1024px) {
    .metrics-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  .projects-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
  
  @media (min-width: 1024px) {
    .projects-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  /* Card Styles */
  .card {
    background-color: white;
    border-radius: 0.75rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    padding: 1.5rem;
  }
  
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .card-title {
    font-size: 1.25rem;
    font-weight: bold;
  }
  
  .icon-bg {
    padding: 0.5rem;
    border-radius: 9999px;
  }
  
  .icon-bg.orange {
    background-color: #f97316;
  }
  .icon-bg.yellow {
    background-color: #eab308;
  }
  .icon-bg.blue {
    background-color: #3b82f6;
  }
  .icon-bg.green {
    background-color: #22c55e;
  }
  
  .icon-bg svg {
    width: 1.5rem;
    height: 1.5rem;
    color: white;
  }
  
  .card-value {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
  }
  
  .card-subvalue {
    color: #6b7280;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
  
  .card-details {
    display: flex;
    justify-content: space-between;
    font-size: 0.75rem;
    color: #6b7280;
  }
  
  .card-details span {
    display: flex;
    align-items: center;
  }
  
  .card-details svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0.25rem;
  }
  
  /* Project Item Styles */
  .project-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 0;
  }
  
  .project-info {
    display: flex;
    align-items: center;
  }
  
  .project-icon {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 500;
    margin-right: 0.75rem;
  }
  
  .project-icon.purple {
    background-color: #a855f7;
  }
  .project-icon.green {
    background-color: #22c55e;
  }
  .project-icon.blue {
    background-color: #3b82f6;
  }
  
  .project-name {
    font-weight: 500;
  }
  
  .project-update {
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .project-views {
    font-size: 0.875rem;
    color: #6b7280;
  }
  
 
  
  /* Experience and Achievement Styles */
  .experience-achievement-container {
    display: flex;
    flex-direction: column;
  }
  
  @media (min-width: 1024px) {
    .experience-achievement-container {
      flex-direction: row;
    }
  }
  
  .experience-section,
  .achievement-section {
    flex: 1;
    padding: 1.5rem;
  }
  
  @media (min-width: 1024px) {
    .experience-section {
      border-right: 1px solid #e5e7eb;
      padding-right: 1.5rem;
    }
  
    .achievement-section {
      padding-left: 1.5rem;
    }
  }
  
  .experience-item,
  .achievement-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
  
  .experience-icon,
  .achievement-icon {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.75rem;
    margin-top: 0.25rem;
    color: #6b7280;
  }
  
  .experience-content,
  .achievement-content {
    flex: 1;
  }
  
  .experience-title,
  .achievement-title {
    font-weight: 500;
  }
  
  .experience-org,
  .achievement-description {
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .experience-date {
    font-size: 0.75rem;
    color: #6b7280;
  }
  
/* Additional custom styles if needed beyond Tailwind */
.major-project-item {
  position: relative;
  width: 100%;
  margin: 20px auto;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.major-project-info {
  padding: 16px;
  border-bottom: 1px solid #e5e7eb;
}

.major-project-name {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1f2937;
  margin-bottom: 4px;
}

.major-project-name a {
  color: inherit;
  text-decoration: none;
  transition: color 0.2s;
}

.major-project-name a:hover {
  color: #2563eb;
}

.major-project-date {
  font-size: 0.875rem;
  color: #6b7280;
}

.iframe-container {
  position: relative;
  width: 100%;
  height: 600px; /* Adjust height as needed */
  overflow: hidden;
}

.project-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 10%;
  height: 10%;
  border: none;
  background: #f9fafb;
}




