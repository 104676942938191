.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: #111827;
  color: white;
  transition: width 0.3s;
  overflow: hidden;
  z-index: 9;
}

.sidebar.open {
  width: 16rem;
}

.sidebar.closed {
  width: 0rem;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.profile-info-sidebar {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.closed .profile-info-sidebar .profile-details-sidebar{
  display: none;
}

.closed .logo{
  display: none;
}

.profile-name-sidebar {
  color: grey;
  padding-left: 2.5rem;
  font-size: 1.3rem;
  margin-bottom: 0.7rem;
}

.profile-image-sidebar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 9999px;
  margin-left:1rem ;
  margin-bottom: .5rem;

}

.profile-details {
  margin-left: 0.75rem;
  transition: opacity 0.2s;
}

.sidebar.closed .profile-details {
  opacity: 0;
}

.profile-name {
  font-weight: 600;
  font-size: larger;
}

.profile-view {
  font-size: 0.75rem;
  color: #9ca3af;
}

.sidebar-toggle {
  background: none;
  border: none;
  color: #9ca3af;
  cursor: pointer;
}

.sidebar-toggle:hover {
  color: white;
}


.nav-list {
  list-style-type: none;
  padding: 0;
  margin-left: 0.5rem;
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  margin: 0.5rem 0;
  border-radius: 0.375rem;
  cursor: pointer;
}

.nav-item.active {
  background-color: #374151;
  color: white;
}

.nav-item:not(.active) {
  color: #9ca3af;
}

.nav-item:hover:not(.active) {
  background-color: #374151;
  color: white;
}

.nav-icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.75rem;
}

.nav-text {
  transition: opacity 0.2s;
}

.sidebar.closed .nav-text {
  opacity: 0;
}

.sidebar.open {
  width: 16rem;
}

.sidebar.closed {
  width: 4rem;
}

.sidebar-toggle {
  background: none;
  border: none;
  color: #9ca3af;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.sidebar-toggle:hover {
  background-color: #374151;
  color: white;
}

.nav-text {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.sidebar.closed .nav-text {
  opacity: 0;
  width: 0;
}

.profile-details-sidebar {
  width: 100%;
  margin-left: 1rem;
  padding: 1rem;

}

.sidebar.closed .profile-details {
  display: none;
}

.sidebar {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}


.logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #9050ff;
}

.logo-icon {
  animation: sparkle 2s ease-in-out infinite;
   color: #9050ff;
   margin-right: .2rem;
   
}

.closed .logo-icon{
  margin-left: -.3rem;
}

@keyframes sparkle {
  0%, 100% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.2); opacity: 0.8; }
}
