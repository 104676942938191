.linkedin-container {
  width: 100%;
  max-width: 64rem;
  margin: 0 auto;
}

.profile-card {
  border-radius: 0.75rem;
  overflow: hidden;
}

.profile-card h2{
  margin-left: 1rem;
}


.profile-content {
  display: flex;
  gap: 2rem;
  margin-top: .5rem;
  width: 100%;
}

.profile-info {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-left: 1.7rem;
}

.exp{
  width: 60%;
  margin-top: none;
}


.profile-image {
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  margin-left: -1rem;
}

.profile-details {
  display: flex;
  flex-direction: column;
  margin-top: .5rem;
  margin-left: -2rem;
}

.profile-name {
  font-weight: 500;
  color: #4B5563;
}

.name{
  display: flex;
  gap: .5rem;
  font-size: 16px;
  color: #586069;}

.profile-headline {
  font-size: 16px;
  color: #586069;}

.profile-location {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 16px;
  color: #586069;}

.profile-bio {
  font-size: 16px;
  color: #586069;  display: flex;
}

.profile-section-skill {
  margin-top: .8rem;
}

.section-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}






/* Experience styles */
.experience-item {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  background-color: #f6f8fa;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.company-logo {
  width: 3rem;
  height: 3rem;
  border-radius: 0.25rem;
}

.experience-details h4 {
  font-weight: 600;
  color: #111827;
}

.experience-meta {
  color: #4B5563;
}

.experience-date {
  font-size: 0.875rem;
  color: #6B7280;
}

/* Education styles */
.education-item {
  padding: 1rem;
  background-color: #f6f8fa;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.education-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  
}

.education-details h4 {
  color: #2d2d2d;
  font-size: small;
  margin-bottom: .5rem;
}

.education-details{
  display: flex;
  flex-direction: column;
  gap: .2rem;
}

.education-meta {
  color: #4B5563;
}

.education-grade {
  color: #4B5563;
}

.education-date {
  font-size: 0.875rem;
  color: #6B7280;
}

.education-link {
  color: #2563eb;
  transition: color 0.2s;
}

.education-link:hover {
  color: #1d4ed8;
}

/* Skills styles */
.skills-container {
  display: flex;
  flex-wrap: wrap;
  gap: .3rem;  
}

.skill-tag {
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
}

.skill-tag.assessed {
  background-color: #DEF7EC;
  color: #03543f;
}

.skill-tag.regular {
  background-color: #DBEAFE;
  color: #1e40af;
}

.icon {
  width: 1rem;
  height: 1rem;
  font-size: 16px;
  color: #586069;
}

.icon-medium {
  width: 1.25rem;
  height: 1.25rem;
}

.space-y-4 > * + * {
  margin-top: 1rem;
}

.space-y-2 > * + * {
  margin-top: 0.5rem;
}

@media (max-width: 768px) {
  .profile-content {
    padding: 1rem;
  }
  
  .experience-item {
    flex-direction: column;
  }
  
  .company-logo {
    width: 2.5rem;
    height: 2.5rem;
  }
}



.experience-details{
  display: flex;
  flex-direction:  column;
  gap: .4rem;

}




.Calendar{
  color: rgb(101, 161, 29);
}

.Workflow{
  color: #2563eb;
    
}

.Folders{
  color: #eb256e;
    
}


.Book{
  color: #93a600;
}

.Calendar, .Workflow, .Folders, .Book{
  margin-right: .4rem;
  font-weight: bold;
  color: #4B5563;
}