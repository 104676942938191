:root {
  --primary-color: #4a90e2;
  --secondary-color: #f3f4f6;
  --text-color: #333;
  --border-color: #e2e8f0;
  --success-color: #48bb78;
  --error-color: #f56565;
}

.main-profile-page-container {
  max-width: 80%;
  margin: 2rem auto;
  margin-top: 4rem;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.glass-effect {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.gradient-blob {
  position: fixed;
  top: -50%;
  left: -50%;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(74, 144, 226, 0.3) 0%, rgba(74, 144, 226, 0) 70%);
  z-index: -1;
}

.gradient-blob2 {
  position: fixed;
  bottom: -50%;
  right: -50%;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(74, 144, 226, 0.3) 0%, rgba(74, 144, 226, 0) 70%);
  z-index: -1;
}

.main-profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.main-profile-image-container {
  width: 100px;
  height: 100px;
  margin-right: 1.5rem;
}

.main-profile-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid var(--primary-color);
}

.main-profile-title {
  font-size: 2rem;
  color: var(--text-color);
  margin: 0;
}

.main-profile-edit-button {
  margin-left: auto;
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.main-profile-edit-button:hover {
  background-color: #3a7bc8;
}

.main-profile-form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.main-profile-form-group {
  margin-bottom: 1.5rem;
}

.main-profile-form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--text-color);
  font-weight: 500;
}

.main-profile-form-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.main-profile-form-input:focus {
  outline: none;
  border-color: var(--primary-color);
}

.main-profile-form-input:disabled {
  background-color: var(--secondary-color);
  cursor: not-allowed;
}

.main-profile-resume-section {
  grid-column: 1 / -1;
}

/* .main-profile-file-input {
  display: none;
} */

.main-profile-resume-upload {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: white;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.main-profile-resume-upload:hover {
  background-color: #3a7bc8;
}

.main-profile-resume-link {
  color: var(--primary-color);
  text-decoration: none;
}

.main-profile-resume-link:hover {
  text-decoration: underline;
}

.main-profile-projects-section {
  margin-top: 2rem;
  display: flex;
  gap: 1.5rem;
}

.main-profile-projects-form {
  background-color: var(--secondary-color);
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
}

.main-profile-add-project-button,
.main-profile-delete-project-button {
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.main-profile-add-project-button:hover,
.main-profile-delete-project-button:hover {
  background-color: #3a7bc8;
}

.main-profile-delete-project-button {
  background-color: var(--error-color);
}

.main-profile-delete-project-button:hover {
  background-color: #e53e3e;
}

.main-profile-form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}

.main-profile-cancel-button,
.main-profile-save-button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.main-profile-cancel-button {
  background-color: var(--secondary-color);
  color: var(--text-color);
}

.main-profile-cancel-button:hover {
  background-color: #e2e8f0;
}

.main-profile-save-button {
  background-color: var(--success-color);
  color: white;
}

.main-profile-save-button:hover {
  background-color: #38a169;
}

.main-profile-save-button:disabled {
  background-color: #a0aec0;
  cursor: not-allowed;
}

.main-profile-toast-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.main-profile-toast {
  padding: 1rem;
  border-radius: 4px;
  color: white;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-profile-toast-success {
  background-color: var(--success-color);
}

.main-profile-toast-error {
  background-color: var(--error-color);
}

.main-profile-toast-close {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}


.form-main{

}

@media (max-width: 768px) {
  .main-profile-page-container {
    padding: 1rem;
  }

  .main-profile-header {
    flex-direction: column;
    text-align: center;
  }

  .main-profile-image-container {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .main-profile-edit-button {
    margin-left: 0;
    margin-top: 1rem;
  }

  .main-profile-form-grid {
    grid-template-columns: 1fr;
  }
}
