

.project-content::-webkit-scrollbar {
    display: none;
}
  .carousel-container {
    display: flex;
    align-items: center;
  }
  
  .carousel-button {
    border-radius: 9999px;
    background-color: rgb(188, 188, 188);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: #4b5563;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    transition: all 0.3s ease;
    margin: .3rem;
    padding: .4rem;
  }
  
  .carousel-button:hover {
    color: #1f2937;
    background-color: #f9fafb;
  }
  
  .carousel-button.prev {
    left: -1rem;
  }
  
  .carousel-button.next {
    right: -1rem;
  }
  

  
  .project-card {
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 0.75rem;
    transition: all 0.5s ease;
    opacity: 0;
    visibility: hidden;
    transform: translateX(100%);
  }
  
  .project-card.active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
  
  .project-header {
    border-bottom: 1px solid #f3f4f6;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
  }
  
  .project-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #1f2937;
    margin-bottom: 1rem;
  }
  
  .project-title a {
    color: inherit;
    text-decoration: none;
    color: #2563eb;
    transition: color 0.3s ease;
    font-size: large;
  }
  
  .project-title a:hover {
    color: #2563eb;
  }
  
  .project-date {
    font-size: 0.875rem;
    color: #6b7280;
    margin-bottom: 1rem;
  }
  
  .date-icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }
  
  .project-content {
    position: relative;
    width: 100%;
    height: 20rem;
  }
  
  .project-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border: none;
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }
  
  .carousel-indicators {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 1rem;
  }
  
  .indicator {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 9999px;
    background-color: #d1d5db;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .indicator.active {
    background-color: #2563eb;
  }
  



  /* Update the project-card styling */
.project-card {
    position: relative; 
    width: 100%;
    border-radius: 0.75rem;
    transition: opacity 0.5s ease;
    display: none; /* Hide by default */
}

.project-card.active {
    display: block; /* Show when active */
    opacity: 1;
}

.carousel-content {
    position: relative;
    width: 100%;
    overflow: visible;
}

.project-content {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.project-iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    display: block; 
}

